import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  GetOfferList,
  authMerchant,
  payByWallet,
  verifyPromo,
  UserCashback,
} from "../services/krti.service";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import KrtiLogo from "../assets/images/krti-logo.svg";
import BlackRightIcon from "../assets/images/blackRightIcon.svg";
import OfferHeadIcon from "../assets/images/offerHeadImg.svg";
import Accordion from "react-bootstrap/Accordion";
import CircleDollerIcon from "../assets/images/circleDoller_icon.svg";
import WalletIcon from "../assets/images/wallet_icon.svg";
import Leftbackarrow from "../assets/images/leftbackarrow.svg";

import OfferList from "./Cart";
const currency = process.env.REACT_APP_CURRENCY;
export function formatCurrency(value) {
  try {
    // Ensure the input is treated as a number
    const number = Number(value);
    if (isNaN(number)) {
      return `KWD ${value}`;
    }
    if (!currency) {
      return `KWD ${value}`;
    }

    // Determine the appropriate number of fraction digits
    const hasDecimalPart = number % 1 !== 0;
    const minimumFractionDigits = hasDecimalPart ? 3 : 3;
    const maximumFractionDigits = hasDecimalPart ? 3 : 3;

    // Format the number using toLocaleString
    let formattedNumber = number.toLocaleString("en-KW", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    });

    // Replace the currency code with the desired symbol if the currency is KWD
    if (currency === "KWD") {
      formattedNumber = formattedNumber.replace("KD", "KWD");
    }
    // 'د.ك'
    return formattedNumber;
  } catch (error) {
    // Handle errors gracefully by returning a default format
    console.error(error.message);
    return `KWD ${value}`;
  }
}

const Checkout = ({
  walletValue,
  cartValue,
  setWalletValue,
  handleNextStep,
  payload,
  setStep,
  promoDetail,
  setPromodetail,
  handlePrevStep,
  widgetCompleted,
  setWidgetCompleted,
  useWallet,
  setUseWallet,
  setCashback,
  cashback,
  setTranscation,
}) => {
  const [finalAmount, setFinalAmount] = useState(Number(cartValue));
  const [userId, setUserID] = useState(null);
  const [offerType, setOfferType] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [isValidPromo, setIsvalidPromo] = useState(true);
  const [isOfferList, setIsOfferList] = useState(false);
  const [promoText, setPromotext] = useState(null);
  const [error, setError] = useState("");
  const [updatedWallet, setUpdatedWallet] = useState(0);
  const [totalCashback, setTotalCashback] = useState(0);
  const [offerList, setOfferList] = useState([]);
  const [offerArr, setOfferArr] = useState([]);
  const [voucherArr, setVoucherArr] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [checkedIndex, setCheckedIndex] = useState(0);
  const [walletDisable , setWalletDisable] = useState(false)
  const [checked , setChecked] = useState(false)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const dicountAmount = formatCurrency(cartValue - finalAmount);
  let merchant_secret;
  let client_id;
  console.log('checkedIndex::: ', checkedIndex);
  
  try {
    merchant_secret = queryParams.get("merchant_secret") || null;
    client_id = queryParams.get("client_id") || null;
  } catch (error) {
    console.error("Failed to parse payload:", error);
  }

  const handleUseWallet = (e) => {
    if(checked){
      setUseWallet(false);
    }
    if (walletValue && finalAmount > 0) {
      setUseWallet(e.target.checked);
    }
    setChecked((prev)=>!prev)
  };

  // checkout calculation
  useEffect(() => {
    if (useWallet) {
      const newCartValue =
        parseFloat(cartValue) -
        parseFloat(walletValue) -
        (parseFloat(promoDetail?.discount_value) ||
          parseFloat(promoDetail?.amount) ||
          0);
      if (newCartValue >= 0) {
        // setWalletValue(0);
        setFinalAmount(newCartValue);
      } else {
        setUpdatedWallet(
          finalAmount > 0 ? finalAmount : Math.abs(newCartValue)
        );
        setFinalAmount(0);
      }
    } else {
      const initialCartValue = +cartValue || 0;
      const finalCartValue =
        initialCartValue -
        (promoDetail?.discount_value || promoDetail?.amount || 0);
      const userSessionData = sessionStorage.getItem("K-user");
      const parsedSessionData =
        userSessionData !== "undefined" ? JSON?.parse(userSessionData) : null;
      const walletAmount =
        parsedSessionData?.wallet_amount ?? userDetail?.wallet_amount ?? 0;
      setWalletValue(walletAmount);
      setFinalAmount(finalCartValue >= 0 ? finalCartValue : 0);
    }
  }, [useWallet, promoDetail?.discount_value, promoDetail?.amount]);
  // promo function
  const handleApplyPromoCode = async (code, validation = cashback) => {
    try {
      if (!code) return null;
      if (!finalAmount) return setPromoCode("");

      const res = await verifyPromo(code, cartValue,userId,payload?.brand_id);
      if (res?.status === 200 && res?.data?.statusCode === 200) {
        setIsvalidPromo(true);
        setPromodetail(res?.data?.data?.data);
        setPromotext(res?.data?.message || "Promo Applied");
        setIsOfferList(false);
        setOfferType(res?.data?.data?.type);
      } else {
        setIsvalidPromo(false);
        setPromotext(res?.data?.message || "Invalid promocode");
        setPromodetail({});
        setOfferType(null);
        setIsOfferList(false);
        toast.error(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
      }
      console.log(error.message);
    }
  };

  const removePromo = () => {
    setPromodetail({});
    setCheckedIndex(null);
    setOfferType(null);
    setPromotext(null);
    setPromoCode("");
  };

  // on submit function
  const HandleProceed = () => {
    if (finalAmount) {
      sessionStorage.setItem("cartTotal", finalAmount);
      handleNextStep();
    } else {
      walletPay();
    }
  };

  const handleCheckboxChange = (event, promo, index) => {
    setCheckedIndex(index === checkedIndex ? null : index);
    // setCheckedIndex(()=>index)
    if (event.target.checked) {
      handleSubmitPromo(promo);
    } else {
      removePromo();
    }
  };

  const walletPay = async () => {
    const value = cartValue - (parseFloat(promoDetail?.discount_value) || parseFloat(promoDetail?.amount) || 0);
    // console.log('cartValue::: ', cartValue);
    // console.log('value::: ', value);
    // console.log('promoDetail?.discount_value::: ', promoDetail?.discount_value);
    // console.log('promoDetail?.amount::: ', promoDetail?.amount);
    try {
      setError(null);
      const req = {
        paymentType: "Debit",
        amount: value > 0 ? value : 0,
        transaction_case: true,
        user_id: userId,
        brand_id: payload?.brand_id,
        coupon_id: promoDetail?.id || null,
        discount_amount:
        promoDetail?.discount_value || promoDetail?.amount || 0,
        offer_type : promoDetail?.type
      };
      if (payload?.transaction_type !== "order") {
        req.paymentFor = "voucherpurchase";
        req.voucher_id = payload?.voucher_id;
      }
      const res = await payByWallet(req);
      if (res?.data?.statusCode === 200) {
        console.log("res?.data::: ", res?.data.data);
        if(payload.transaction_type !== 'order'){
          window.location.href = "/payment/success";
        }else{
          handleNextStep(3);
          setTranscation(res?.data.data);
        }
      } else {
        setError(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
        setError(msg || "Something went wrong");
      }
      console.log(error.message);
    }
  };

  const fetchOffers = async () => {
    try {
      setError("");
      const req = {
        amount: +cartValue,
        merchant_id: payload?.brand_id,
        user_id: userId,
      };
      const res = await GetOfferList(req);
      if (res?.data?.statusCode === 200) {
        setTotalCashback(res?.data?.data?.total_cashback_earned)
        let data = res?.data?.data?.merchanOffers;
        let vouchers = res?.data?.data?.userOwnedVouchers;
        let arr = [];
        arr.push(...data.slice(0, 3));
        arr.push(...vouchers.slice(0, 3));
        setOfferArr([...data.slice(0, 3)]);
        // data.length === 0 && setCheckedIndex(null) 
        setVoucherArr([...vouchers.slice(0, 3)]);
        console.log("arr::: ", arr);
        if (data?.length > 0) {
          //   setOfferList(data.slice(0, 3));
          setPromoCode(data?.[0]?.campaign_promo);
          handleApplyPromoCode(
            data?.[0]?.campaign_promo,
            res?.data?.data?.cashback || 0
          );
        }
        else{
          fetchCashback()
          setCheckedIndex(null)
        }
        // vouchers?.length && setVoucherList(vouchers.slice(0, 3));
        setOfferList((prev) => [...prev, ...arr]);
        // setOfferList(...arr);
      } else {
        // setError(res?.message || res?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
        setError(msg || "Something went wrong");
      }
      console.log(error.message);
    }
  };

  const back = () => {
    console.log('back::: ');
    if (payload?.is_register) {
      setWidgetCompleted(!widgetCompleted);
    } else {
      setUseWallet(false);
      setPromodetail(null);
      setOfferType(null);
      handlePrevStep(1);
      setPromoCode("");
    }
  };

  useEffect(() => {
    const authKrtiMerchant = async () => {
      try {
        const req = {
          amount: payload?.amount,
          mobile: payload?.mobile,
          mobile_code: payload?.mobile_code,
          transaction_type: payload?.transaction_type,
          voucher_id: payload?.voucher_id,
          merchant_secret,
          client_id,
        };
        const res = await authMerchant(payload?.brand_id, req);
        if (res?.data?.statusCode === 200) {
          sessionStorage.setItem("K-token", res?.data?.data?.token);
          Cookies.set("K-token", res?.data?.data?.token);
          if (payload?.is_register) {
            sessionStorage.setItem(
              "K-user",
              JSON?.stringify(res?.data?.data?.userDetails || {})
            );
            setUserDetail(res?.data?.data?.userDetails);
          }
          // setIsAuthorized(true)
        } else {
          toast.error(res?.message || res?.data?.message);
          // setIsAuthorized(false)
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          // alert(msg);
          console.error(msg);
        }
        console.log(error.message);
      }
    };

    if (payload?.is_register) {
      if (!userDetail?.id) authKrtiMerchant();
    }
  }, []);

  const fetchCashback = async () => {
    try {
      const req = {
        amount: Number(finalAmount),
        merchant_id: payload?.brand_id,
        user_id: userId,
      };

      if (offerType && promoDetail?.id) {
        req.coupon_type = offerType === "promo" ? "voucher" : "coupon";
      }
      const res = await UserCashback(req);
      if (res?.data?.statusCode === 200) {
        setCashback(res?.data?.data?.cashback);
      } else {
        toast.error(res?.message || res?.data?.message);
        // setIsAuthorized(false)
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();
        // alert(msg);
        console.error(msg);
      }
      console.log(error.message);
    }
  };
  useEffect(() => {

    if (finalAmount) {
      fetchCashback();
    } else {
      setCashback(0);
    }
  }, [finalAmount]);

  useEffect(() => {
    if (payload?.transaction_type === "order") {
      if (userId) fetchOffers();
    }
  }, [userId]);

  const handleSubmitPromo = (promo) => {
    setPromoCode(promo);
    handleApplyPromoCode(promo);
  };

  useEffect(() => {
    const userSessionData = sessionStorage.getItem("K-user");
    const parsedSessionData =
      userSessionData !== "undefined" ? JSON.parse(userSessionData) : null;

    const walletAmount =
      parsedSessionData?.wallet_amount ?? userDetail?.wallet_amount ?? 0;
    const userId = parsedSessionData?.id ?? userDetail?.id ?? null;

    setWalletValue(walletAmount);
    setUserID(userId);
  }, [userDetail]);

  return (
    <>
      {!isOfferList && (
        <div className="mobilePageWrapper pt_34 ">
          <div className="plr_24">
            <div className='mb_34'>

              <button className='backCirclebtn platonicBlueCircle' onClick={()=>back()}>
                <img className='ml_min2' src={Leftbackarrow} alt='Leftbackarrow' />
              </button>

            </div>


            <div className="mb_30">
              <img src={KrtiLogo} alt="KrtiLogo" />
            </div>
            {payload.transaction_type !== "voucher" ? (
              <>
                <h2 className="fontSize22 fontWeight500 blackClr_text mb_8">
                  Cashback overview
                </h2>
                <div className="mb_16">
                  <div className="Groupinput" >
                    <input
                      type="text"
                      name="new_password"
                      placeholder="Enter promo code"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                    <div className="inputgroup_append">
                      <span className="Groupinput_text">
                        {!promoText && (
                          <button
                            className="helloSummerClrText fontSize16 fontWeight400 textDecoration_underline emptyBtn"
                            onClick={() => handleSubmitPromo(promoCode)}
                          >
                            Apply
                          </button>
                        )}
                        {promoText && (
                          <button
                            onClick={removePromo}
                            className="blackStallionClrText fontSize16 fontWeight400 textDecoration_underline emptyBtn"
                          >
                            Remove
                          </button>
                        )}
                      </span>
                    </div>
                  </div>
                  {!isValidPromo && <div className="errorMsg">{promoText}</div>}
                  {isValidPromo && promoText && (
                    <div className="successMsg ">{promoText}</div>
                  )}
                </div>
                <div className="blackStallionFrame mb_40">
                  {/* <div>
                <img src={ClarityCoinIcon} alt="ClarityCoinIcon" />
              </div> */}
                  <div>
                    <p className="fontSize16 fontWeight500 helloSummerClrText">
                      Total Cashback Earned
                    </p>
                    <h2 className="fontSize36 fontWeight500 helloSummerClrText">
                      {/* {formatCurrency(cashback) ? formatCurrency(cashback) : formatCurrency(totalCashback)} */}
                      {formatCurrency(totalCashback)}
                    </h2>
                  </div>
                </div>
              </>
            ) : null}
            <div>
              {payload.transaction_type !== "voucher" ? (
                <>
                  <div className="displayFlex alignItem_center justifyContent_spacebetween mb_24">
                    <div className="displayFlex alignItem_center gap12">
                      <div>
                        <img src={OfferHeadIcon} alt="OfferHeadIcon" />
                      </div>
                      <div>
                        <h5 className="fontSize22 fontWeight500 blackClr_text">
                          Offers
                        </h5>
                      </div>
                    </div>
                    <div>
                      {offerList?.length !== 0 && offerArr?.length !== 0 ? (<button
                        className="helloSummerClrText fontSize16 fontWeight400 gap12 displayFlex emptyBtn alignItem_center"
                        onClick={() => setIsOfferList(true)}
                      >
                        <span>View all </span>
                        <img src={BlackRightIcon} alt="BlackRightIcon" />
                      </button>) : null}
                    </div>
                  </div>
                  {
                    offerList?.length !== 0 && offerArr?.length !== 0? 
                  (<div className="mb_50">
                    <Accordion defaultActiveKey="0">
                      {offerList.map((data, i) =>
                        data.code === undefined ? (
                          <Accordion.Item eventKey={`${i}`} key={i}>
                            <Accordion.Header>
                              <div>
                                <h2 className="fontSize24 fontWeight500 blackStallionClrText">
                                  {formatCurrency(data?.discount_value)} Off
                                  {/* {data?.offer_type === "Value" ? formatCurrency(data?.discount_value) : `${formatCurrency(data?.discount_value)} %` } Off */}
                                </h2>
                              </div>
                              <div className="ml_auto mr_16">
                                <label className="whiteBtn">
                                  {data?.campaign_promo}
                                </label>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="fontSize16 fontWeight400 blackStallionClrText mb_16">
                                {data?.details}
                              </p>

                              <label className="custom_switch textAlignRight">
                                <input
                                  className="toggle_checkbox"
                                  type="radio"
                                  checked={i === checkedIndex}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      data?.campaign_promo,
                                      i
                                    )
                                  }
                                />
                                <div className="toggle_switch"></div>
                                {/* <span className="toggle-label">sasasasasasa</span> */}
                              </label>
                            </Accordion.Body>
                          </Accordion.Item>
                        ) : null
                      )}
                    </Accordion>
                  </div>) : null
                  }

                  <div className="displayFlex alignItem_center justifyContent_spacebetween mb_24">
                    <div className="displayFlex alignItem_center gap12">
                      <div>
                        <img src={CircleDollerIcon} alt="CircleDollerIcon" />
                      </div>
                      <div>
                        <h5 className="fontSize22 fontWeight500 blackClr_text">
                          Vouchers
                        </h5>
                      </div>
                    </div>
                    <>
                    {offerList?.length !== 0 && voucherArr?.length !== 0 ? (<button
                        className="helloSummerClrText fontSize16 fontWeight400 gap12 displayFlex emptyBtn alignItem_center"
                        onClick={() => setIsOfferList(true)}
                      >
                        <span>View all </span>
                        <img src={BlackRightIcon} alt="BlackRightIcon" />
                      </button>) : null}
                    </>
                  </div>
                  {
                    offerList?.length !== 0 && voucherArr?.length !== 0 ?
                  (<div className="mb_50">
                    <Accordion>
                      {offerList.map((data, i) =>
                        data.campaign_promo === undefined ? (
                          <Accordion.Item eventKey={`${i}`} key={i}>
                            <Accordion.Header>
                              <div>
                                <h2 className="fontSize24 fontWeight500 blackStallionClrText">
                                  {formatCurrency(data?.amount)} Off
                                </h2>
                              </div>
                              <div className="ml_auto mr_16">
                                <label className="whiteBtn">{data?.code}</label>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="fontSize16 fontWeight400 blackStallionClrText mb_16">{data?.details}</p>

                              <label className="custom_switch textAlignRight">
                                <input
                                  className="toggle_checkbox"
                                  type="radio"
                                  checked={checkedIndex === i}
                                  id={i}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, data?.code, i)
                                  }
                                />
                                <div className="toggle_switch"></div>
                                {/* <span className="toggle-label">sasasasasasa</span> */}
                              </label>
                            </Accordion.Body>
                          </Accordion.Item>
                        ) : null
                      )}
                    </Accordion>
                  </div>) : null
                  }
                </>
              ) : null}

              <div className="mb_16">
                <div className="displayFlex alignItem_center gap12">
                  <div>
                    <img src={WalletIcon} alt="WalletIcon" />
                  </div>
                  <div>
                    <h5 className="fontSize22 fontWeight500 blackClr_text">
                      Wallet
                    </h5>
                  </div>
                </div>
              </div>
              <div className="mb_50">
                <div className="displayFlex alignItem_center justifyContent_spacebetween walletFrame">
                  <div>
                    <h2 className="fontSize24 fontWeight500 blackStallionClrText">
                      {formatCurrency(walletValue)}
                    </h2>
                  </div>

                  <div>
                    <label className="custom_switch textAlignRight">
                      <input
                        name="walletCheckbox"
                        className="toggle_checkbox"
                        type="checkbox"
                        disabled = {!finalAmount > 0 && !checked}
                        onChange={(e)=> handleUseWallet(e)}
                      />
                      <div className="toggle_switch"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <h2 className="fontSize22 fontWeight500 blackClr_text mb_12">
              {" "}
              {payload?.transaction_type === "order"
                ? "Order details"
                : "Voucher details"}{" "}
            </h2>

            {/* </div> */}
            <div className="table_detail mb_8">
              <table>
                {
                  <tr>
                    <th> Cart Amount:</th>
                    <td> {formatCurrency(cartValue)} </td>
                  </tr>
                }
                {/* {useWallet &&  */}
                {/* } */}

                {promoDetail?.discount_value || promoDetail?.amount ? (
                  <tr>
                    <th> Promotional discount:</th>
                    <td>
                      -{" "}
                      {formatCurrency(
                        promoDetail?.discount_value || promoDetail?.amount
                      )}{" "}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {
                  <tr>
                    {useWallet && (
                      <th>
                        <label htmlFor="useWallet">Wallet Cash </label>
                      </th>
                    )}
                    <td>
                      {" "}
                      {useWallet &&
                        "- " +
                          formatCurrency(
                            walletValue >
                              cartValue -
                                (parseFloat(promoDetail?.discount_value) ||
                                  parseFloat(promoDetail?.amount) ||
                                  0)
                              ? cartValue -
                                  (parseFloat(promoDetail?.discount_value) ||
                                    parseFloat(promoDetail?.amount) ||
                                    0)
                              : walletValue
                          )}{" "}
                    </td>
                  </tr>
                }
                <tfoot>
                  <tr>
                    <th>
                      <label htmlFor="useWallet">Total discount</label>
                    </th>
                    <td>- {dicountAmount} </td>
                  </tr>
                  <tr>
                    <th className="fontweight500">Total amount</th>
                    <td>
                      <span className="fontweight500">
                        {formatCurrency(finalAmount)}
                      </span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            {payload.transaction_type !== "voucher" ? (
              <p className="fontSize16 fontWeight500 helloSummerClrText mb_24 textAlignRight">
                Assured Cashback of {formatCurrency(cashback)}
              </p>
            ) : null}
            <p className="fontSize14 fontWeight400 blackStallionClrText">
              Return and refund policy <br /> In case of return, we ensure quick <br />
              refunds. Full amount will be refunded <br /> excluding Convenience
              Fee.
            </p>
            <div className="displayFlex gap5px center">
              {error && <div className="errorMsg">{error}</div>}
            </div>
          </div>
          <div className="pageFooter displayFlex alignItem_center justifyContent_spacebetween">
              <div>
                <h1 className="fontSize22 fontWeight500 blackClr_text ">
                  Pay {formatCurrency(finalAmount)}
                </h1>
              </div>
              <div>
                <button onClick={HandleProceed} className="blackMainbtn">
                  To payment
                </button>
              </div>
            </div>

           
            {/* </div> */}
        
        </div>
      )}

      {isOfferList && (
        <OfferList
          offerList={offerList}
          offerArr={offerArr}
          voucherArr={voucherArr}
          handlePrevStep={handlePrevStep}
          handleSubmitPromo={handleSubmitPromo}
          handleApplyPromoCode={handleApplyPromoCode}
          removePromo={removePromo}
          isValidPromo={isValidPromo}
          promoCode={promoCode}
          payload={payload}
          setPromoCode={setPromoCode}
          promoText={promoText}
          setIsOfferList={setIsOfferList}
          checkedIndex={checkedIndex}
          handleCheckboxChange={handleCheckboxChange}
        />
      )}
    </>
  );
};

export default Checkout;
